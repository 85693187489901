import { IEnvironment } from "src/app/models/environment.model";
import { EnvironmentNames } from "src/app/util/environment.util";

export const environment: IEnvironment = {
    environmentName: EnvironmentNames.STAGE1,
    production: false,
    redirectUrl: "https://shale-and-tight-surveillance-stage.azure.chevron.com",
    dmSearchServiceScope: "97f86bfc-6a96-470e-b39e-399095bd26a1/.default",
    dmSearchServiceBaseUrl: "https://dmsearchservice-csdu-stage2-cvx.azurewebsites.net/v1/api",
    saoIngestionServiceBaseUrl: "https://sao-ingestion-stage2-cvx.azurewebsites.net",
    osduSurveillanceRecordKind: "chevron:sao-osdu:surveillance-analysis-optimization:6.0.0",
    saoFrontendApiBaseUrl: "https://shale-and-tight-surveillance-api-stage.azurewebsites.net",
    shaleTightSaoClientId: "d7b390de-7354-4edd-bd0a-cc1137bd71fe",
    shaleTightSaoScope: "d7b390de-7354-4edd-bd0a-cc1137bd71fe/.default"
}
